<template>
  <div class="body">
    <img :src="orgBgUrl" class="bg_img" v-if="orgBgUrl" />
    <slot> </slot>
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Footer from "@/components/common/Footer";

export default {
  name: "AuthParent",
  created() {
    this.getOrganisationAssets();
  },
  components: {
    Footer,
  },
  computed: {
    ...mapGetters({
      orgBgUrl: "getOrganisationBgImg",
    }),
  },
  methods: {
    ...mapActions({
      getOrganisationAssets: "fetchOrganisationLogoAndLoginBackgroundImage",
    }),
  },
};
</script>

<style scoped>
.bg_img {
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
}
</style>
